import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from "react-toastify";
import reportWebVitals from './reportWebVitals';

import App from './App';
import { store } from "./app/store"

import './index.scss';
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ToastContainer autoClose={3000} />
    <App />
  </Provider>
);
reportWebVitals();

import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create an API for dashboard tasks
const dashbordTaskAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'dashbordTaskAPI',
    endpoints: (builder) => ({

        // Query to get all task list
        getAllTaskList: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTask/GetAllTaskList`,
                method: 'POST',
                body: transformRequest(data), // Transform request data before sending
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to add a dashboard task
        addDashboardTask: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTask/AddTask`,
                method: 'POST',
                body: transformRequest(data), // Transform request data before sending
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to update a dashboard task
        updateDashboardTask: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTask/UpdateTasks`,
                method: 'POST',
                body: transformRequest(data), // Transform request data before sending
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to delete a dashboard task
        deleteDashboardTask: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTask/DeleteTask?plTaskKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get dashboard task Status list
        getDashboardTaskStatus: builder.query({
            query: (data) => ({
                url: `/DashBoardTask/GetAllTaskStatus?pbIncludeBlank=${data.pbIncludeBlank}&pbIncludeNotCompleted=${data.pbIncludeNotCompleted}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get dashboard task Status list
        getDashboardTaskPriority: builder.query({
            query: (data) => ({
                url: `/DashBoardTask/GetAllTaskPriorities?pbIncludeBlank=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    })
});

// Destructure hooks from dashboardTask API for usage
export const {
    useGetAllTaskListMutation,
    useAddDashboardTaskMutation,
    useUpdateDashboardTaskMutation,
    useDeleteDashboardTaskMutation,
    useLazyGetDashboardTaskStatusQuery,
    useLazyGetDashboardTaskPriorityQuery,
} = dashbordTaskAPI;

export default dashbordTaskAPI; // Export dashboardTask API as default

// Import statements
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create administration API
const administrationAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'administrationAPI',
    endpoints: (builder) => ({
        // Security Groups Endpoints

        // Get all security groups
        getAllSecurityGroups: builder.query({
            query: () => ({
                url: `/AdminSecurity/GetAllSecurityGroups`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Menu Items APIs

        // Get all security group menu items list
        getAllSecurityGroupsMenuItemsList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupMenuItemsList?plSecurityGroupKey=${data.plSecurityGroupKey}&plSecurityParentMenuItemKey=${data.plSecurityParentMenuItemKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get all security group all menu items list
        getAllSecurityGroupsAllMenuItemsList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupAllMenuItemsList?plSecurityGroupKey=${data.plSecurityGroupKey}&plSecurityParentMenuItemKey=${data.plSecurityParentMenuItemKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group menu item
        updateSecurityGroupMenuItem: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityGroupMenuItem`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group child menu item
        updateSecurityGroupChildMenuItem: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityCheckParentFromChild`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        copySecurityGroupPermissionByGroupById: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/CopySecurityPermissionsGroupByGroupId`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        /** Reports & Extracts API */

        // Get all security group report categories list
        getAllSecurityGroupReportCategoriesList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupReportCategoriesList?plSecurityGroupKey=${data}`,
                method: 'GET'
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get all security group reports list
        getAllSecurityGroupReportsList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupReportsList?plSecurityGroupKey=${data.plSecurityGroupKey}&plReportCategoryKey=${data.plReportCategoryKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group report
        updateSecurityGroupReport: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityGroupReport`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group report category
        updateSecurityGroupReportCategory: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityGroupReportCategory`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Miscellaneous securables API

        // Get all security group securable categories list
        getAllSecurityGroupSecurableCategoriesList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupSecurableCategoriesList?plSecurityGroupKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get all security group securable items list
        getAllSecurityGroupSecurableItemsList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupSecurableItemsList?plSecurityGroupKey=${data.plSecurityGroupKey}&plSecurableCategoryKey=${data.plSecurableCategoryKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group securable item
        updateSecurityGroupSecurableItem: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityGroupSecurableItem`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group securable category
        updateSecurityGroupSecurableCategory: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityGroupSecurableCategory`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Users API

        // Get all users name list
        getAllUsersNameList: builder.query({
            query: () => ({
                url: `/AdminSecurity/GetAllUsersNameList`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get all security group users get available list
        getAllSecurityGroupUsersGetAvailableList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllSecurityGroupUsersGetAvailableList?plSecurityGroupKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Delete security group user add/remove
        deleteSecurityGroupUserAddRemove: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/DeleteSecurityGroupUserAddRemove?plSecurityGroupKey=${data.plSecurityGroupKey}&plUserKey=${data.plUserKey}&pbAdd=${data.pbAdd}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get assigned user list
        getAssignedUserList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllAssingSecurityGroupUsersList?plSecurityGroupKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get unassigned user list
        getUnassignedUserList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllUnAssingSecurityGroupUsersList?plSecurityGroupKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Security Groups

        // Get all user security groups list
        getAllUserSecurityGroupsList: builder.query({
            query: (data) => ({
                url: `/AdminSecurity/GetAllUserSecurityGroupsList?plUserKey=${data.plUserKey}&pbIncludeGlobalAdmin=${data.pbIncludeGlobalAdmin}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update user security group
        updateUserSecurityGroup: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateUserSecurityGroup`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Add security group
        addSecurityGroup: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/AddSecurityGroup`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update security group rename
        updateSecurityGroupRename: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/UpdateSecurityGroupRename`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        copySecurityGroupPermission: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/CopySecurityPermissionsByGroupId`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Delete security group
        deleteSecurityGroup: builder.mutation({
            query: (data) => ({
                url: `/AdminSecurity/DeleteSecurityGroup?plSecurityGroupKey=${data}`,
                method: 'POST',
                // body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    })
})

// Export hooks for API endpoints
export const {
    useLazyGetAllSecurityGroupsQuery,

    useLazyGetAllSecurityGroupsMenuItemsListQuery,
    useLazyGetAllSecurityGroupsAllMenuItemsListQuery,
    useUpdateSecurityGroupMenuItemMutation,
    useUpdateSecurityGroupChildMenuItemMutation,
    useCopySecurityGroupPermissionByGroupByIdMutation, // copy security permission into existing security Group

    useLazyGetAllSecurityGroupReportCategoriesListQuery,
    useLazyGetAllSecurityGroupReportsListQuery,
    useUpdateSecurityGroupReportMutation,
    useUpdateSecurityGroupReportCategoryMutation,

    useLazyGetAllSecurityGroupSecurableCategoriesListQuery,
    useLazyGetAllSecurityGroupSecurableItemsListQuery,
    useUpdateSecurityGroupSecurableItemMutation,
    useUpdateSecurityGroupSecurableCategoryMutation,

    useLazyGetAllUsersNameListQuery,
    useLazyGetAllSecurityGroupUsersGetAvailableListQuery,
    useDeleteSecurityGroupUserAddRemoveMutation,
    useLazyGetAssignedUserListQuery,
    useLazyGetUnassignedUserListQuery,

    useLazyGetAllUserSecurityGroupsListQuery,
    useUpdateUserSecurityGroupMutation,
    useUpdateSecurityGroupRenameMutation,
    useCopySecurityGroupPermissionMutation, // copy security permission into new security Group
    useAddSecurityGroupMutation,
    useDeleteSecurityGroupMutation,

} = administrationAPI;

// Default export of administration API
export default administrationAPI;

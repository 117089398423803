import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "./SwalService.scss"



export const SwalServices = withReactContent(Swal);

const customClass= {
    container: '',
    popup: '',
    header: '',
    title: '',
    closeButton: '',
    icon: '',
    image: '',
    htmlContainer: '',
    input: '',
    inputLabel: '',
    validationMessage: '',
    actions: '',
    confirmButton: 'btn theme-button',
    denyButton: 'btn dark-btn',
    cancelButton: 'btn dark-btn',
    loader: '',
    footer: '',
    timerProgressBar: '',
  }
const SwalAlert = () => {

    const success = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'success',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const error = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'error',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const warning = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const info = (title, text) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'info',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    }

    const confirm = (title,  text, saveBtnText, cancelBtnText, isCancelButton,  htmlContent) => {

        return SwalServices.fire({
            title: title,
            text: text,
            html: htmlContent,
            icon: 'question',
            // iconHtml: '<span className="warning-icons"><img src="/warningtick.png"/></span>',
            showCancelButton: isCancelButton === false ? isCancelButton : true,
            confirmButtonText: saveBtnText,
            cancelButtonText: cancelBtnText,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            customClass:customClass,
            
        }).then((result) => {
            if (result.value) {
                return true;
            } else if (result.dismiss === SwalServices.DismissReason.cancel) {
                return false;
            }
        });
    };

    const inputConfirm = (title, input1Label, input2Label, input1Placeholder, input2Placeholder, saveBtnText, cancelBtnText) => {
        return SwalServices.fire({
            title: title,
            html: `
                <div style="text-align: left;" class="input-confirm">
                    <div class="inputs">
                        <label for="lotNumber">${input1Label}</label>
                        <input type="text" id="lotNumber" class="swal2-input" placeholder="${input1Placeholder}">
                    </div>
                    <div class="inputs">
                        <label for="quantity">${input2Label}</label>
                        <input type="number" id="quantity" class="swal2-input" placeholder="${input2Placeholder}">
                    </div>
                </div>
            `,
            showCancelButton: true,
            confirmButtonText: saveBtnText,
            cancelButtonText: cancelBtnText,
            preConfirm: () => {
                const lotNumber = document.getElementById('lotNumber').value;
                const quantity = document.getElementById('quantity').value;
                if (!lotNumber || !quantity) {
                    Swal.showValidationMessage(`Please enter both fields`);
                    return null;
                }
                return { lotNumber, quantity };
            },
            customClass: customClass,
        }).then((result) => {
            if (result.isConfirmed) {
                return result.value;
            } else if (result.dismiss === SwalServices.DismissReason.cancel) {
                return null;
            }
        });
    };
    


    // Export the SwalService functions
    return { confirm, success, warning, info, error, inputConfirm }

};


export default SwalAlert;
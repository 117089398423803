// Import necessary dependencies
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create contactAPI with createApi function
const contactAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'contactAPI',
    endpoints: (builder) => ({

        // Get Contact List endpoint
        getContactList: builder.query({
            query: (data) => ({
                url: `/Contacts/GetContactsList?plContactKey=${data.plContactKey}&plClientKey=${data.plClientKey}&plDepartmentKey=${data.plDepartmentKey}&plDistributorKey=${data.plDistributorKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get All Contacts endpoint
        getAllContacts: builder.query({
            query: (data) => ({
                url: `/Contacts/GetAllContacts?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Add Contact endpoint
        addContact: builder.mutation({
            query: (data) => ({
                url: `/Contacts/AddContacts`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update Contact endpoint
        updateContact: builder.mutation({
            query: (data) => ({
                url: `/Contacts/UpdateContacts`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
});

// Destructure hooks from contactAPI for usage
export const {
    useAddContactMutation,
    useUpdateContactMutation,
    useLazyGetContactListQuery,
    useLazyGetAllContactsQuery,
} = contactAPI;

export default contactAPI; // Export contactAPI as default

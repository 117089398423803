export const securityKey = {

    /** Workspace Page */
    MY_WORKSPACE: 55,

    /** Dashboard Page */
    DASHBOARD: 24,
    DASHBOARD_SCOPES: 1,
    DASHBOARD_TASKS: 2,
    DASHBOARD_LOANERS: 3,
    DASHBOARD_EMAILS: 4,
    DASHBOARD_SHIPPING_STATUS: 5,
    DASHBOARD_INVENTORY: 6,
    DASHBOARD_ACQUISITIONS: 8,
    DASHBOARD_SEARCHES: 10,
    DASHBOARD_REPAIR_METRICS: 11,
    DASHBOARD_REPAIR_TIMES: 12,
    DASHBOARD_REPAIR_ITEM_COSTS: 13,
    DASHBOARD_TURN_AROUND_TIMES: 14,
    DASHBOARD_FLAGS: 15,
    DASHBOARD_PROFITABILITY: 16,
    DASHBOARD_REV_EXP_DISTRIBUTION: 17,
    DASHBOARD_POINTS: 18,
    DASHBOARD_TECH_HOURS: 19,
    DASHBOARD_GP_INTEGRATION: 20,
    DASHBOARD_DEVELOPMENT_LIST: 21,
    DASHBOARD_COLOR_PALETTE: 22,
    DASHBOARD_STEVE: 23,

    /** Clients Page */
    CLIENTS: 25,
    CLIENTS_MAIN: 37,
    CLIENTS_DEPARTMENTS: 51,
    CLIENTS_FLAGS: 53,
    CLIENTS_CONTACTS: 54,

    /** Departments Page */
    DEPARTMENTS: 38,
    DEPARTMENTS_GPOS: 52,
    DEPARTMENTS_MAIN: 56,
    DEPARTMENTS_ADDRESSES: 57,
    DEPARTMENTS_SCOPES: 58,
    DEPARTMENTS_SCOPE_TYPES: 59,
    DEPARTMENTS_SUB_GROUPS: 60,
    DEPARTMENTS_CONTACTS: 61,
    DEPARTMENTS_DOCUMENTS: 62,
    DEPARTMENTS_MODEL_MAX_CHARGES: 63,

    /** Repairs Page */
    REPAIRS: 26,
    REPAIRS_SCOPE_IN: 64,
    REPAIRS_FLAGS: 65,
    REPAIRS_SHIPPING: 66,
    REPAIRS_ADDRESS: 68,
    REPAIRS_DETAIL: 70,
    REPAIRS_EXPENSES: 71,
    REPAIRS_INVENTORY: 72,
    REPAIRS_STATUS: 73,
    REPAIRS_DOCUMENTS: 74,
    REPAIRS_POINTS: 75,
    REPAIRS_INSTRUMENTS: 76,

    /** Scopes Page */
    SCOPES: 27,
    SCOPES_SUB: 39,
    SCOPES_SCOPE_TYPES: 40,

    /** Repair Items Page */
    REPAIR_ITEMS: 28,
    REPAIR_ITEMS_SUB: 41,
    REPAIR_ITEMS_INSTRUMENTS: 42,

    /** Inventory Page */
    INVENTORY: 29,
    INVENTORY_SUB: 43,
    INVENTORY_PURCHASE_ORDERS: 44,
    INVENTORY_INVENTORY_ADJUSTMENTS: 45,
    INVENTORY_LOT_NUMBER_MAINTENANCE: 46,
    INVENTORY_LOANER_SCOPES: 47,
    INVENTORY_SUPPLIERS: 48,

    /** Invoices Page */
    INVOICES: 9,

    /** Acquisition Page */
    ACQUISITIONS: 30,
    SUPPLIER_POS: 49,
    RECEIVE_RETURN_ACQUISITIONS: 50,

    /** Carts Page */
    CARTS: 31,

    /** Contracts Page */
    CONTRACTS: 32,

    /** Quality Page */
    QUALITY: 33,

    /** Reports & Exports Page */
    REPORTS_EXTRACTS: 34,

    /** Tools Page */
    TOOLS: 35,

    /** Administration Page */
    ADMINISTRATION: 36,
    ADMINISTRATION_SECURITY: 77,
    ADMINISTRATION_COMPANIES: 78,
    ADMINISTRATION_DISTRIBUTORS: 79,
    ADMINISTRATION_PRICING_LISTS: 80,
    ADMINISTRATION_REFERENCE_TABLES: 81,
    ADMINISTRATION_MANAGE_STAFF: 82,

}
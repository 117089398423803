// Import necessary dependencies and utilities
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create client API using createApi function from Redux Toolkit
const purchaseOrdersAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'purchaseOrdersAPI',
    endpoints: (builder) => ({

        getAllSuppliers: builder.query({
            query: (data) => ({
                url: `Inventory/GetAllSuppliers?plInventorySizeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllPurchaseOrders: builder.query({
            query: (data) => ({
                url: `Inventory/GetAllPO?plSupplierKey=${data.plSupplierKey}&pbIncludeClosed=${data.pbIncludeClosed}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getPoDetailsById: builder.query({
            query: (data) => ({
                url: `Inventory/GetPODetailsById?plSupplierPOKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAvailableInventoryForPurhcaseOrder: builder.mutation({
            query: (data) => ({
                url: `/Inventory/GetInventoryAvailableForSupplierPOList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateUnitCost: builder.mutation({
            query: (data) => ({
                url: `/Inventory/UpdateUnitCostInventoryAndSupplierSize`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
});

export const { useLazyGetAllSuppliersQuery,
    useLazyGetAllPurchaseOrdersQuery,
    useLazyGetPoDetailsByIdQuery,
    useGetAvailableInventoryForPurhcaseOrderMutation,
    useUpdateUnitCostMutation,
} = purchaseOrdersAPI;

export default purchaseOrdersAPI;

import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultBaseQuery } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

const authAPI = createApi({
    baseQuery: defaultBaseQuery,
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (details) => ({
                url: '/Authentication/UserLogin',
                method: 'POST',
                body: transformRequest(details),
            }),
            invalidateTags: ['authentication'],
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    })
});

export const { useLoginMutation } = authAPI;

export default authAPI;
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create an API for dashboard tasks
const taskTypeAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'taskTypeAPI',
    endpoints: (builder) => ({

        // Query to get task type
        getAllTaskType: builder.query({
            query: () => ({

                url: `/DashBoardTaskTypes/GetAllTaskType`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get task type List
        getAllTaskTypeList: builder.query({
            query: () => ({
                url: `/DashBoardTaskTypes/GetAllTaskTypeList`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to add a task type
        addTaskType: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTaskTypes/AddTaskType`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to update task type
        updateTaskType: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTaskTypes/UpdateTaskType`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to delete a task type
        DeleteTaskType: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTaskTypes/DeleteTaskType?plTaskTypeKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
});

export const {
    useLazyGetAllTaskTypeQuery,
    useLazyGetAllTaskTypeListQuery,
    useAddTaskTypeMutation,
    useUpdateTaskTypeMutation,
    useDeleteTaskTypeMutation,
} = taskTypeAPI;

export default taskTypeAPI;
import React, { useState } from "react";
import "../../components/grid2/MolGrid2.scss";
import Checkbox from "../../components/ui/inputs/checkBox/CheckBox";

const CollapsibleGrid = () => {
  const [collapsedRows, setCollapsedRows] = useState({});

  const toggleRow = (rowId) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  // Define your data array
  const rowData = [
    {
      id: "1",
      name: "Dashboard",
      subTableHeaders: ["Company", "Salary", "Date On", "Date off"],
    },
    {
      id: "2",
      name: "Dashboard",
      subTableHeaders: ["Sub Table Item", "Sub No Permissions", "Sub View Permissions", "Sub Edit Permissions"],
    },
    {
      id: "3",
      name: "Dashboard",
      subTableHeaders: ["Company", "Salary", "Date On", "Date off"],
    },
  ];

  return (
    <div className="collapsible-table table-custom">
      <table>
        <colgroup>
          <col style={{ width: "2%" }} />
          <col style={{ width: "32%" }} />
          <col style={{ width: "22%" }} />
          <col style={{ width: "22%" }} />
          <col style={{ width: "22%" }} />
        </colgroup>
        <thead>
          <tr className="heading-row">
            <th></th>
            <th>Menu Item</th>
            <th>No Permissions</th>
            <th>View Permissions</th>
            <th>Edit Permissions</th>
          </tr>
        </thead>

        <tbody>
          {rowData.map((row) => (
            <React.Fragment key={row.id}>
              <tr className={`parent-row ${collapsedRows[row.id] ? 'collapsed' : ''}`}>
                <td className="first-td" onClick={() => toggleRow(row.id)}>
                  <span className={`bi bi-chevron-${collapsedRows[row.id] ? 'down' : 'right'}`}></span>
                </td>
                <td>{row.name}</td>
                <td>
                  <Checkbox />
                </td>
                <td>
                  <Checkbox />
                </td>
                <td>
                  <Checkbox />
                </td>
              </tr>
              {collapsedRows[row.id] && (
                <tr>
                  <td className="first-td"></td>
                  <td className="sub-table" colSpan="4">
                    <table>
                      <thead>
                        <tr>
                          {row.subTableHeaders.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Scopes</td>
                          <td>
                            <Checkbox />
                          </td>
                          <td>
                            <Checkbox />
                          </td>
                          <td>
                            <Checkbox />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CollapsibleGrid;

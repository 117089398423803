import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware"
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Define API endpoints using createApi from Redux Toolkit
const documentAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'documentAPI',
    endpoints: (builder) => ({

        // Endpoint to get all documents list
        getAllDocumentsList: builder.query({
            query: (data) => ({
                url: `/Documents/GetAllDocumentsList?plDocumentKey=${data.plDocumentKey}&plOwnerKey=${data.plOwnerKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation function to add a document
        addDocument: builder.mutation({
            query: (data) => ({
                url: '/Documents/AddDocument',
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation function to update a document
        updateDocument: builder.mutation({
            query: (data) => ({
                url: '/Documents/UpdateDocument',
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation function to delete a document
        deleteDocument: builder.mutation({
            query: (data) => ({
                url: '/Documents/DeleteDocument',
                method: 'DELETE',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

// Extract hooks for endpoints from documentAPI
export const {
    useLazyGetAllDocumentsListQuery,
} = documentAPI;

// Export the documentAPI
export default documentAPI;

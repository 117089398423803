import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create an API for dashboard tasks
const taskLoanerAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'taskLoanerAPI',
    endpoints: (builder) => ({

        addTaskLoaner: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTaskLoaner/AddTaskLoaner`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateTaskLoaner: builder.mutation({
            query: (data) => ({
                url: `/DashBoardTaskLoaner/UpdateTaskLoaner`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllTaskLoanerList: builder.query({
            query: (data) => ({
                url: `/DashBoardTaskLoaner/GetAllTaskLoanerList?plTaskKey=${data.plTaskKey}&plTaskScopeTypeKey=${data.plTaskScopeTypeKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),

});

export const {
    useAddTaskLoanerMutation,
    useUpdateTaskLoanerMutation,
    useLazyGetAllTaskLoanerListQuery
} = taskLoanerAPI;

export default taskLoanerAPI;
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

const scopesAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'scopesAPI',
    endpoints: (builder) => ({

        // Define a mutation to get dashboard scopes
        getDashboardScopes: builder.mutation({
            query: (data) => ({
                // url: `/Dashboard/GetDashboardScopeData`,
                url: `/Dashboard/GetDashboardScopeDataList`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get all ScopeTypes
        getAllScopeTypes: builder.query({
            query: (data) => ({
                url: `/Scopes/GetAllScopeType`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get all Scopes
        getAllScopes: builder.query({
            query: (data) => ({
                url: `/Scopes/GetAllScopes?plDepartmentKey=${data.plDepartmentKey}&psScopeIsDead=${data.psScopeIsDead}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all scopes List
        getAllScopesList: builder.mutation({
            query: (data) => ({
                url: `/Scopes/GetAllScopesList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Add Scope
        addScope: builder.mutation({
            query: (data) => ({
                url: `/Scopes/AddScope`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateScope: builder.mutation({
            query: (data) => ({
                url: `/Scopes/UpdateScope`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteScope: builder.mutation({
            query: (data) => ({
                url: `/Scopes/DeleteScope?plScopeKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get Scope Details By Scope Id
        getScopeByScopeId: builder.query({
            query: (data) => ({
                url: `/Scopes/GetScopeByScopeId?plScopeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getRepaireByScopeId: builder.query({
            query: (data) => ({
                url: `/Scopes/GetRepaireByScopeId?plScopeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Sell Scope 
        sellScope: builder.mutation({
            query: (data) => ({
                url: `Scopes/UpdateScopeSale`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // update Scope Sale Price
        updateScopeSalePrice: builder.mutation({
            query: (data) => ({
                url: `Scopes/UpdateScopeSalePrice`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // processReturn
        scopeSaleReturn: builder.mutation({
            query: (data) => ({
                url: `Scopes/UpdateScopeSaleReturn`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Check if the scope has an open Repair
        checkOpenRepair: builder.query({
            query: (data) => ({
                url: `Scopes/CheckOpenRepaireScope?plScopeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getScopeComments: builder.query({
            query: (data) => ({
                url: `Scopes/GetScopeComment?plScopeKey=${data.plScopeKey}&checkType=${data.checkType}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    })
});

// Destructure generated hooks from scopesAPI
export const {
    useGetDashboardScopesMutation,

    useLazyGetAllScopeTypesQuery,
    useLazyGetAllScopesQuery,
    useGetAllScopesListMutation,
    useAddScopeMutation,
    useUpdateScopeMutation,
    useDeleteScopeMutation,
    useLazyGetScopeByScopeIdQuery,
    useLazyGetRepaireByScopeIdQuery,

    useSellScopeMutation,
    useUpdateScopeSalePriceMutation,
    useScopeSaleReturnMutation,
    useLazyCheckOpenRepairQuery,

    useLazyGetScopeCommentsQuery,
} = scopesAPI;

// Export scopesAPI as default
export default scopesAPI;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import authReducer from "./slice/authSlice";
import authAPI from "./services/authAPI";
import scopesAPI from "./services/scopesAPI";
import clientAPI from "./services/clientAPI";
import flagsAPI from "./services/flagsAPI";
import contactAPI from "./services/contactAPI";
import commonAPI from "./services/commonAPI";
import departmentAPI from "./services/departmentAPI";
import repairAPI from "./services/repairAPI";
import documentAPI from "./services/documentAPI";
import modelMaxChargesAPI from "./services/modelMaxChargesAPI";
import administrationAPI from "./services/administrationAPI";
import serviceLocationAPI from "./services/serviceLocationAPI";
import dashbordTaskAPI from "./services/dashbordTaskAPI";
import taskTypeAPI from "./services/taskTypeAPI";
import pricingListAPI from "./services/pricingListAPI";
import subGroupsAPI from "./services/subGroupsAPI";
import scopeTypesAPI from "./services/scopeTypesAPI";
import repairItemsAPI from "./services/repairItemsAPI";
import productIdAPI from "./services/productIdAPI";
import instrumentsAPI from "./services/instrumentsAPI";
import taskLoanerAPI from "./services/taskLoanerAPI";
import inventoryAPI from "./services/inventoryAPI";
import inventorySizeAssemblyAPI from "./services/inventorySizeAssemblyAPI";
import purchaseOrdersAPI from "./services/purchaseOrdersAPI";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [authAPI.reducerPath]: authAPI.reducer,
        [scopesAPI.reducerPath]: scopesAPI.reducer,
        [clientAPI.reducerPath]: clientAPI.reducer,
        [flagsAPI.reducerPath]: flagsAPI.reducer,
        [contactAPI.reducerPath]: contactAPI.reducer,
        [commonAPI.reducerPath]: commonAPI.reducer,
        [departmentAPI.reducerPath]: departmentAPI.reducer,
        [repairAPI.reducerPath]: repairAPI.reducer,
        [documentAPI.reducerPath]: documentAPI.reducer,
        [modelMaxChargesAPI.reducerPath]: modelMaxChargesAPI.reducer,
        [administrationAPI.reducerPath]: administrationAPI.reducer,
        [serviceLocationAPI.reducerPath]: serviceLocationAPI.reducer,
        [dashbordTaskAPI.reducerPath]: dashbordTaskAPI.reducer,
        [pricingListAPI.reducerPath]: pricingListAPI.reducer,
        [taskTypeAPI.reducerPath]: taskTypeAPI.reducer,
        [taskTypeAPI.reducerPath]: taskTypeAPI.reducer,
        [scopeTypesAPI.reducerPath]: scopeTypesAPI.reducer,
        [subGroupsAPI.reducerPath]: subGroupsAPI.reducer,
        [repairItemsAPI.reducerPath]: repairItemsAPI.reducer,
        [productIdAPI.reducerPath]: productIdAPI.reducer,
        [instrumentsAPI.reducerPath]: instrumentsAPI.reducer,
        [taskLoanerAPI.reducerPath]: taskLoanerAPI.reducer,
        [inventoryAPI.reducerPath]: inventoryAPI.reducer,
        [inventorySizeAssemblyAPI.reducerPath]: inventorySizeAssemblyAPI.reducer,
        [purchaseOrdersAPI.reducerPath]: purchaseOrdersAPI.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        authAPI.middleware,
        scopesAPI.middleware,
        clientAPI.middleware,
        flagsAPI.middleware,
        contactAPI.middleware,
        commonAPI.middleware,
        departmentAPI.middleware,
        repairAPI.middleware,
        documentAPI.middleware,
        modelMaxChargesAPI.middleware,
        administrationAPI.middleware,
        serviceLocationAPI.middleware,
        dashbordTaskAPI.middleware,
        pricingListAPI.middleware,
        taskTypeAPI.middleware,
        subGroupsAPI.middleware,
        scopeTypesAPI.middleware,
        repairItemsAPI.middleware,
        productIdAPI.middleware,
        instrumentsAPI.middleware,
        taskLoanerAPI.middleware,
        inventoryAPI.middleware,
        inventorySizeAssemblyAPI.middleware,
        purchaseOrdersAPI.middleware,
    )

})
setupListeners(store.dispatch)
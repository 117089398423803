import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create client API using createApi function from Redux Toolkit
const inventorySizeAssemblyAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'inventorySizeAssemblyAPI',
    endpoints: (builder) => ({

        getAllInventoryAssemblyList: builder.mutation({
            query: (data) => ({
                url: `/Inventory/GetAllInventoryAssemblyList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateInventoryAssembly: builder.mutation({
            query: (data) => ({
                url: `/Inventory/UpdateInventoryAssembly`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInventoryAssembly: builder.mutation({
            query: (data) => ({
                url: `/Inventory/DeleteInventoryAssembly?plInventoryAssemblyKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllInventoryItemsByInventorySizeType: builder.query({
            query: (data) => ({
                url: `/Inventory/GetAllInventoryItemGetFromInventorySizeType?psInstrumentType=${data.psInstrumentType}&pbLargeDiameter=${data.pbLargeDiameter}&plInventoryKeyToInclude=${data.plInventoryKeyToInclude}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useGetAllInventoryAssemblyListMutation,
    useUpdateInventoryAssemblyMutation,
    useDeleteInventoryAssemblyMutation,
    useLazyGetAllInventoryItemsByInventorySizeTypeQuery,
} = inventorySizeAssemblyAPI;

export default inventorySizeAssemblyAPI;
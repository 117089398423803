// Import necessary modules and functions
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create API using createApi function
const repairItemsAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'repairItemsAPI',
    endpoints: (builder) => ({

        getRepairItemsList: builder.mutation({
            query: (data) => ({
                url: `/RepairItems/GetRepairItemsList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllRepairItems: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetAllRepairItems?psRigidOrFlexible=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getRepairItemByRepairItemKey: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetRepairItemsBylRepairItemKey?plRepairItemKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateRepairItems: builder.mutation({
            query: (data) => ({
                url: `/RepairItems/UpdateRepairItems`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteRepairItems: builder.mutation({
            query: (data) => ({
                url: `/RepairItems/DeleteRepairItems?plRepairItemKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllRepairStatus: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetRepairStatus?pbIncludeBlank=${data.pbIncludeBlank}&plRepairStatusID=${data.plRepairStatusID}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getRepairLevels: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetRepairLevels?pbIncludeBlank=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getRepairReasons: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetRepairReasons?plRepairReasonKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        /** Price Tab */
        getRepairitemPricesList: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/GetRepairItemPricingList',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateRepairItemPricingDetails: builder.mutation({
            query: (data) => ({
                url: `/RepairItems/UpdateRepairItemPricingDetails`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        /** Implied Items */
        getImpliedItemsList: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/GetRepairItemImpliedItemsList',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getParentItemsList: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/GetRepairItemParentItemsList',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addImpliedItems: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/AddImpliedItemByplRepairItemKey',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateImpliedItems: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/UpdateImpliedItemBylTechKey',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteImpliedItems: builder.mutation({
            query: (data) => ({
                url: `/RepairItems/DeleteImpliedItemByplRepairItemKey?plRepairItemChildKey=${data.plRepairItemChildKey}&plRepairItemParentKey=${data.plRepairItemParentKey}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),


        /** Implied Inventory */
        getImpliedInventoryList: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/GetRepairItemsImpliedInventoryList',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateImpliedInventory: builder.mutation({
            query: (data) => ({
                url: '/RepairItems/UpdateRepairItemsImpliedInventory',
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        /** Technicians */
        getAllTechnicians: builder.query({
            query: () => ({
                url: `/RepairItems/GetAllTechnicians`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getTechnicianById: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetTechnicianById?plTechnicianKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
});

export const {
    useGetRepairItemsListMutation,
    useLazyGetAllRepairItemsQuery,
    useLazyGetRepairItemByRepairItemKeyQuery,
    useUpdateRepairItemsMutation,
    useDeleteRepairItemsMutation,
    useLazyGetAllRepairStatusQuery,
    useLazyGetRepairLevelsQuery,
    useLazyGetRepairReasonsQuery,

    useGetRepairitemPricesListMutation,
    useUpdateRepairItemPricingDetailsMutation,

    useGetImpliedItemsListMutation,
    useGetParentItemsListMutation,
    useAddImpliedItemsMutation,
    useUpdateImpliedItemsMutation,
    useDeleteImpliedItemsMutation,

    useGetImpliedInventoryListMutation,
    useUpdateImpliedInventoryMutation,

    useLazyGetAllTechniciansQuery,
    useLazyGetTechnicianByIdQuery

} = repairItemsAPI;
export default repairItemsAPI;
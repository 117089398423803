// Import necessary dependencies and utilities
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create client API using createApi function from Redux Toolkit
const clientAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'clientAPI',
    endpoints: (builder) => ({

        // Query to get all clients
        getAllClients: builder.query({
            query: (data) => ({
                url: `/Client/GetAllClientList?plServiceLocationKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get department list
        getDepartmentList: builder.query({
            query: (data) => ({
                url: `/Client/GetDepartmentList?plClientKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get client details by ID
        getClientDetailsById: builder.query({
            query: (data) => ({
                url: `/Client/GetClientDetailsByClientId?plClientKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to add a client
        addClient: builder.mutation({
            query: (data) => ({
                url: `/Client/AddClient`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to update a client
        updateClient: builder.mutation({
            query: (data) => ({
                url: `/Client/UpdateClient`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to delete a client
        deleteClient: builder.mutation({
            query: (data) => ({
                url: `/Client/DeleteClient?plClientKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all national accounts
        getAllNationalAccounts: builder.query({
            query: (data) => ({
                url: `/Client/getAllNationalAccounts?plClientKeyToInclude=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

// Extract hooks for easy access to various API requests
export const {
    useAddClientMutation,
    useUpdateClientMutation,
    useDeleteClientMutation,
    useLazyGetAllClientsQuery,
    useLazyGetClientDetailsByIdQuery,
    useLazyGetAllNationalAccountsQuery,
} = clientAPI;

// Export clientAPI as default
export default clientAPI;

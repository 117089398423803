// Import necessary modules and functions
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create API using createApi function
const flagsAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'flagsAPI',
    endpoints: (builder) => ({

        // Endpoint to get flag list
        getFlagsList: builder.query({
            query: (data) => ({
                url: `/Flag/GetFlagList?plOwnerKey=${data.plOwnerKey}&plFlagTypeKey=${data.plFlagTypeKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Endpoint to add flag
        addFlag: builder.mutation({
            query: (data) => ({
                url: `/Flag/AddFlag`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Endpoint to update flag
        updateFlag: builder.mutation({
            query: (data) => ({
                url: `/Flag/UpdateFlag`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Endpoint to delete flag
        deleteFlag: builder.mutation({
            query: (data) => ({
                url: `/Flag/DeleteFlag?plFlagKey=${data.lFlagKey}&plUserKey=${data.plUserKey}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

// Destructure hooks from the flagsAPI object
export const {
    useLazyGetFlagsListQuery,
    useAddFlagMutation,
    useUpdateFlagMutation,
    useDeleteFlagMutation
} = flagsAPI;

// Export the flagsAPI object as default
export default flagsAPI;

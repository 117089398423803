// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create common API
const commonAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'commonAPI',
    endpoints: (builder) => ({

        // Qurey to get City/State based on zip code (USA)
        getCityStateByZipCode: builder.query({
            query: (data) => ({
                url: `/Client/GetCityStateUSA?zipCode=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get service location
        getServiceLocation: builder.query({
            query: (data) => ({
                url: `/ServiceLocation/GetAllServiceLocation?plUserKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all country list
        getAllCountryList: builder.query({
            query: () => ({
                url: `/Country/GetAllCountries`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all state list
        getAllStateList: builder.query({
            query: () => ({
                url: `/State/GetAllStates`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all pricing categories
        getAllPricingCategory: builder.query({
            query: () => ({
                url: `/PricingCategory/GetAllPricingCategories`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all sales representatives
        getAllSalesReps: builder.query({
            query: () => ({
                url: `/SalesReport/GetAllSalesRepNames`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all distributors
        getAllDistributor: builder.query({
            query: () => ({
                url: `/DistributorName/GetAllDistributorNames`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all payment terms
        getAllPaymentTerms: builder.query({
            query: () => ({
                url: `/PaymentTerms/GetAllPaymentTerms`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all credit limits
        getAllCreditLimit: builder.query({
            query: () => ({
                url: `/CreditLimit/GetAllCreditLimits`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get instrument type list
        getInstrumentTypeList: builder.query({
            query: () => ({
                url: `/InstrumentType/GetInstrumentTypes`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get scope type list
        getScopeTypeList: builder.query({
            query: (data) => ({
                url: `/ScopeType/GetscopeTypeNameList?psInstrumentType=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all system codes
        getAllSystemCodes: builder.query({
            query: (data) => ({
                url: `/SystemCodes/GetAllSystemCodes?plSystemCodesKey=${data.plSystemCodesKey}&plSystemCodesHdrKey=${data.plSystemCodesHdrKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

// Destructuring commonAPI to get specific query hooks
export const {
    useLazyGetCityStateByZipCodeQuery,
    useLazyGetServiceLocationQuery,
    useLazyGetAllCountryListQuery,
    useLazyGetAllStateListQuery,
    useLazyGetAllPricingCategoryQuery,
    useLazyGetAllSalesRepsQuery,
    useLazyGetAllDistributorQuery,
    useLazyGetAllCreditLimitQuery,
    useLazyGetAllPaymentTermsQuery,
    useLazyGetInstrumentTypeListQuery,
    useLazyGetScopeTypeListQuery,
    useLazyGetAllSystemCodesQuery,
} = commonAPI;

export default commonAPI; // Exporting commonAPI

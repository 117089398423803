import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Define pricingListAPI with createApi function
const pricingListAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'pricingListAPI',
    endpoints: (builder) => ({

        // Get all Pricing categories
        getAllPricingLists: builder.query({
            query: (data) => ({
                url: `/PricingCategory/GetAllPricingCategories?&pbDefaultFirst=${data.pbDefaultFirst}&pbActiveOnly=${data.pbActiveOnly}&plPricingCategoryKeyToInclude=${data.plPricingCategoryKeyToInclude}&pbIncludeNone=${data.pbIncludeNone}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get Pricing category details by PricingCategoryKey
        getPricingCategoryDetailsByPricingCategoryKey: builder.query({
            query: (data) => ({
                url: `/AdminPricingLists/GetpricingCategoryBylPricingCategoryId?plPricingCategoryKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Get Pricing details by PricingCategoryKey and RepairItemKey
        getPricingDetails: builder.query({
            query: (data) => ({
                url: `/AdminPricingLists/GetPricingDetails?plPricingCategoryKey=${data.plPricingCategoryKey}&plRepairItemKey=${data.plRepairItemKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        //Update Pricing Details (price update API)
        updatePricingDetails: builder.mutation({
            query: (data) => ({
                url: `/AdminPricingLists/PricingDetailUpdate`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Add Pricing Category
        addPricingCategory: builder.mutation({
            query: (data) => ({
                url: `/AdminPricingLists/AddPricingCategory`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update Pricing Category
        updatePricingCategory: builder.mutation({
            query: (data) => ({
                url: `/AdminPricingLists/UpdatePricingCategory`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Delete Pricing Category
        deletePricingCategory: builder.mutation({
            query: (data) => ({
                url: `/AdminPricingLists/DeletePricingCategory?plPricingCategoryKey=${data}`,
                method: 'DELETE',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllPricingGpo: builder.query({
            query: (data) => ({
                url: `/AdminPricingLists/GetAllPricingGpo?pbIncludeBlank=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Import
        importPricingDetail: builder.mutation({
            query: (data) => ({
                url: `/AdminPricingLists/ImportPricingDetail`,
                method: 'POST',
                body: transformRequest(data),
                // responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Import All 
        importAllPricingDetail: builder.mutation({
            query: (data) => ({
                url: `/AdminPricingLists/ImportPricingDetailAll`,
                method: 'POST',
                body: transformRequest(data),
                // responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Export 
        exportPricingDetail: builder.query({
            query: (data) => ({
                url: `/AdminPricingLists/PricingDetailExport?plPricingCategoryKey=${data}`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Export All 
        exportAllPricingDetail: builder.query({
            query: (data) => ({
                url: `/AdminPricingLists/PricingDetailExportAll?plPricingCategoryKey=${data}`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    })
});

// Export query hooks for each endpoint
export const {
    useLazyGetAllPricingListsQuery,
    useLazyGetPricingCategoryDetailsByPricingCategoryKeyQuery,

    useLazyGetPricingDetailsQuery,
    useUpdatePricingDetailsMutation,

    useAddPricingCategoryMutation,
    useUpdatePricingCategoryMutation,
    useDeletePricingCategoryMutation,
    useLazyGetAllPricingGpoQuery,

    useImportPricingDetailMutation,
    useImportAllPricingDetailMutation,

    useLazyExportPricingDetailQuery,
    useLazyExportAllPricingDetailQuery,
} = pricingListAPI

export default pricingListAPI; // Export pricingListAPI as default

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLoader from "../../components/ui/pageLoader/PageLoader";
import Layout from "../../layouts/Layout";
import ProtectedRoute from "../Routes/ProtectedRoute";
import { hasPermission } from "../../utils/AuthorizeNavigation/authorizeNavigation2";

const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const Login = React.lazy(() => import("../../pages/login/Login"));

const AppRoutes = (props) => {

  return (
    <Router>
      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          <Route name="Layout" path="/"
            element={
              <ProtectedRoute>
                <Layout componentRoutes={props.componentRoutes} />
              </ProtectedRoute>
            }
          >
            {props.componentRoutes.map((route) => (
              !hasPermission(route.securityKey)?.noPermission && (
              <Route
                key={route.path}
                path={route.path}
                name={"test"}
                element={< route.component securityKey={route.securityKey} />}
              />
              )
            ))}

            {/* ------------- Child Routes --------------- */}
            {props.componentRoutes.map((route) => (
              route.children && route.children.map((childRoute) => (
                <Route
                  key={childRoute.path}
                  path={childRoute.path}
                  element={<childRoute.component securityKey={childRoute.securityKey} />}
                />
              ))
            ))}

          </Route>

          <Route exact name="Login" path="/login" element={<Login />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
};
export default AppRoutes;

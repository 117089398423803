import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create an API using Redux Toolkit Query
const modelMaxChargesAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'modelMaxChargesAPI',
    endpoints: (builder) => ({

        // Define endpoint for getting all model max charges
        getAllModelMaxCharges: builder.query({
            query: (data) => ({
                url: `/ModelMaxCharges/GetAllModelMaxChargesList?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateModelMaxCharges: builder.mutation({
            query: (data) => ({
                url: `/ModelMaxCharges/UpdateModelMaxCharge`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addModelMaxCharges: builder.mutation({
            query: (data) => ({
                url: `/ModelMaxCharges/AddModelMaxCharge`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        })
    }),
})

// Destructure the necessary query function for getting all model max charges
export const { useLazyGetAllModelMaxChargesQuery, useUpdateModelMaxChargesMutation, useAddModelMaxChargesMutation } = modelMaxChargesAPI;

// Export the modelMaxChargesAPI object as default
export default modelMaxChargesAPI;
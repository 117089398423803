import { decryptAES } from "../../services/CryptoService";
import SwalAlert from "../../services/swalService/SwalService";
import ToastService from "../../services/toastService/ToastService";

const { error } = SwalAlert();

export const transformSuccessResponse = async (response, meta, arg) => {
    let resData = response.responseData;
    let apiData = resData;


    if (response.isEnType) {
        resData = decryptAES(resData);
        apiData = resData;
    }
    else if (meta.request.url.includes('Export')) {
        return response;
    }
    else if (meta.request.url.includes('GetCityStateUSA')) {
        return response;
    }
    else {
        apiData = JSON.parse(resData);
    }

    // console.log("API --> ", apiData);

    if (apiData.statusCode === 200) {
        return apiData.data;
    }
    else {
        error(apiData.message ? apiData.message : "Something went wrong with API. Please contact admin!!");
        return null;
    }
}

export const transformErrorResponse = (response, meta, args) => {
    // ------> FETCH_ERROR 
    if (response.status && response.status === "FETCH_ERROR") {
        ToastService.error("Oops! error while fetching data from the server. Please try again later.");
        return null;
    }

    // if (response.status && response.status === 404) {
    //     error("API endpoint not found", "Bad Request :(");
    // }

    // ------> PARSING_ERROR & STATUS:500
    if (response.status && response.status === "PARSING_ERROR" && response.originalStatus === 500) {
        const errorMessage = response.data.toLowerCase();
        if (errorMessage.includes("sql server") ||
            errorMessage.includes("connection") ||
            errorMessage.includes("provider")) {
            ToastService.error("Oops! There was an issue with the SQL Server or database connection. Please try again later and ensure that the SQL Server is configured correctly.");
            return null;

        } else {
            error("Oops! There was an issue processing the server's response. Please try again later.");
            return null;
        }
    }

    if (response.status && response.data.responseData) {
        let resData = response.data.responseData;

        //  check if the response is encrypted
        if (response.data.isEnType) {
            resData = decryptAES(resData);
        } else {
            resData = JSON.parse(resData);
        }

        if (response.status === 400) {
            // if (rData.errors) {
            //     let errorMessage = "Validation Error: " + rData.message;
            //     // Iterate through the errors and append them to the error message.
            //     for (const fieldName in rData.errors) {
            //         if (rData.errors.hasOwnProperty(fieldName)) {
            //             errorMessage += "\n" + fieldName + ": " + rData.errors[fieldName].join("\n");
            //         }
            //     }
            //     // Show a toast or alert with the validation error message.
            //     error(errorMessage);
            // }

            if (resData.errors) {
                let errorMessage = `Validation Error: ${resData.message}`;

                // iterate through the errorrs and append them to the error message
                for (const fieldName in resData.errors) {
                    if (resData.errors.hasOwnProperty(fieldName)) {
                        errorMessage += `\n ${fieldName} : ${resData.errors[fieldName].join("\n")} `
                    }
                }
                error(errorMessage);
            }
        }
        else if (response.status === 500) {
            let errorMessage = "something wrong with API call!! Please contact admin";
            error(errorMessage);
        }

    }

}
// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create department API using createApi function
const departmentAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'departmentAPI',
    endpoints: (builder) => ({

        // Query to get all departments
        getAllDepartments: builder.query({
            query: (data) => ({
                url: `/Departments/GetAllDepartments?plDepartmentKey=${data.plDepartmentKey}&plClientKey=${data.plClientKey}&pbIncludeInactive=${data.pbIncludeInactive}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all standard departments
        getAllStandardDepartments: builder.query({
            query: (data) => ({
                url: `/Departments/GetAllStandardDepartments?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get department by ID
        getDepartmentById: builder.query({
            query: (data) => ({
                url: `/Departments/GetDepartmentDetailsByDepartmentId?plDepartmentKey=${data.plDepartmentKey}&plClientKey=${data.plClientKey}&pbIncludeInactive=${data.pbIncludeInactive}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to add a department
        addDepartment: builder.mutation({
            query: (data) => ({
                url: `/Departments/AddDepartment`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to update a department
        updateDepartment: builder.mutation({
            query: (data) => ({
                url: `/Departments/UpdateDepartment`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to delete a department
        deleteDepartment: builder.mutation({
            query: (data) => ({
                url: `/Departments/DeleteDepartment?plDepartmentKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all department GPOs
        getAllDepartmentGpos: builder.query({
            query: (data) => ({
                url: `/DepartmentReportingGroups/getAllGPOsList?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all reporting groups for a department
        getAllReportingGroups: builder.query({
            query: (data) => ({
                url: `/DepartmentReportingGroups/GetAllDepartmentGPOList?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllDepartmentTypes: builder.query({
            query: (data) => ({
                url: `/DepartmentType/GetAllDepartmentTypes`,
                method: 'GET'
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        })
    }),
});

// Export various hooks and the department API
export const {
    useAddDepartmentMutation,
    useUpdateDepartmentMutation,
    useDeleteDepartmentMutation,

    useLazyGetAllStandardDepartmentsQuery,
    useLazyGetAllDepartmentsQuery,
    useLazyGetAllDepartmentGposQuery,
    useLazyGetAllAvailableSubGroupsQuery,
    useLazyGetAllSubGroupsQuery,

    useLazyGetDepartmentByIdQuery,
    useLazyGetAllReportingGroupsQuery,
    useLazyGetAllDepartmentTypesQuery,
} = departmentAPI;

export default departmentAPI;

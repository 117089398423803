import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware"
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

const repairAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'repairAPI',
    endpoints: (builder) => ({

        // Query endpoint to get all repairs
        getAllRepairs: builder.query({
            query: (data) => ({
                url: `/Repair/GetAllRepairs?plScopeKey=${data.plScopeKey}&plDepartmentKey=${data.plDepartmentKey}&plServiceLocationKey=${data.plServiceLocationKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation endpoint to add a new repair
        addRepair: builder.mutation({
            query: (data) => ({
                url: '/Repair/AddRepair',
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation endpoint to update an existing repair
        updateRepair: builder.mutation({
            query: (data) => ({
                url: '/Repair/UpdateRepair',
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation endpoint to delete a repair
        deleteRepair: builder.mutation({
            query: (data) => ({
                url: `/Repair/DeleteRepair?plRepairKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query endpoint to get details of a repair by its ID
        getRepairDetailsById: builder.query({
            query: (data) => ({
                url: `/Repair/GetAllrepairsBylRepairKey?plRepairKey=${data.plRepairKey}&plScopeKey=${data.plScopeKey}&plDepartmentKey=${data.plDepartmentKey}&plServiceLocationKey=${data.plServiceLocationKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query endpoint to get a list of all repair details
        getAllRepairDetailsList: builder.query({
            query: (data) => ({
                url: `/Detail/GetAllRepairDetailsList?plRepairKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query endpoint to get a list of all repair inventory items
        getAllRepairInventoryList: builder.query({
            query: (data) => ({
                url: `/RepairInventory/GetAllRepairInventoryList?plRepairKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query endpoint to get a list of all repair statuses
        getAllRepairStatusList: builder.query({
            query: (data) => ({
                url: `/StatusTran/GetAllRepairStatusesList?plRepairKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllTechs: builder.query({
            query: () => ({
                url: `/Repair/GetAlltechs`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

// Destructure hooks for easier usage in components
export const {
    useAddRepairMutation,
    useUpdateMutation,
    useDeleteRepairMutation,
    useLazyGetRepairDetailsByIdQuery,
    useLazyGetAllRepairsQuery,
    useLazyGetAllRepairDetailsListQuery,
    useLazyGetAllRepairInventoryListQuery,
    useLazyGetAllRepairStatusListQuery,
    useLazyGetAllTechsQuery
} = repairAPI;

export default repairAPI;

// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create department API using createApi function
const subGroupsAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'subGroupsAPI',
    endpoints: (builder) => ({

        // Query to get all subgroups for a department
        getAllSubGroups: builder.query({
            query: (data) => ({
                url: `/SubGroups/GetAllSubGroupsList?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all available subgroups for a department
        getAllAvailableSubGroups: builder.query({
            query: (data) => ({
                url: `/SubGroups/GetAllSubGroupsAvailableList?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to Add subgroups for a department
        addDepartmentSubGroups: builder.mutation({
            query: (data) => ({
                url: `/SubGroups/AddDepartmentSubGroups`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to Remove subgroups for a department
        deleteDepartmentSubGroups: builder.mutation({
            query: (data) => ({
                url: `/SubGroups/DeleteDepartmentSubGroups?plSubGroupKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    })
});

export const {
    useLazyGetAllAvailableSubGroupsQuery,
    useLazyGetAllSubGroupsQuery,
    useAddDepartmentSubGroupsMutation,
    useDeleteDepartmentSubGroupsMutation,
} = subGroupsAPI;

export default subGroupsAPI;
// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create department API using createApi function
const scopeTypesAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'scopeTypesAPI',
    endpoints: (builder) => ({

        // Query to get all scopeTypes for a department
        getAllScopeTypes: builder.query({
            query: (data) => ({
                url: `/ScopeType/GetDepartmentScopeTypesList?plDepartmentKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Query to get all available scopeTypes for a department
        getAllAvailableScopeTypes: builder.query({
            query: (data) => ({
                url: `/ScopeType/GetAvailableDepartmentScopeTypesList?plDepartmentKey=${data.plDepartmentKey}&psScopeTypeDesc=${data.psScopeTypeDesc}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to Add scopeTypes for a department
        addDepartmentScopeTypes: builder.mutation({
            query: (data) => ({
                url: `/ScopeType/AddDepartmentScopeTypes`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Mutation to Remove scopeTypes for a department
        deleteDepartmentScopeTypes: builder.mutation({
            query: (data) => ({
                url: `/ScopeType/DeleteDepartmentScopeTypes?plScopeTypeKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    })
});

export const {
    useLazyGetAllAvailableScopeTypesQuery,
    useLazyGetAllScopeTypesQuery,
    useAddDepartmentScopeTypesMutation,
    useDeleteDepartmentScopeTypesMutation,
} = scopeTypesAPI;

export default scopeTypesAPI;
// Import necessary modules and functions
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

// Create API using createApi function
const instrumentsAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'instrumentsAPI',
    endpoints: (builder) => ({

        /** Instruments */
        getRepairItemInstrumentsList: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/GetInstrumentsList`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getInstrumentDetailsByScopeTypeKey: builder.query({
            query: (data) => ({
                url: `/RepairItemInstruments/InstrumentsDetailsBylScopeTypeKey?plScopeTypeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllInstrumentsCategories: builder.query({
            query: () => ({
                url: `/RepairItemInstruments/GetAllInstrumentsScopeCategories`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateInstruments: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/UpdateInstruments`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        /** Manufacturer APIs */
        getAllManufacturer: builder.query({
            query: (data) => ({
                url: `/RepairItemInstruments/GetInstrumentManufacturersAvailable?plScopeTypeKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getInstrumentManufacturerList: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/GetInstrumentManufacturersList`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addInstrumentManufacturer: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/AddInstrumentManufacturer`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateInstrumentManufacturerStatus: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/UpdateInstrumentManufacturerStatusChange`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInstrumentManufacturer: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/DeleteInstrumentManufacturer?plScopeTypeKey=${data.plScopeTypeKey}&plManufacturerKey=${data.plManufacturerKey}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        /** Manufacturer Model */
        addInstrumentManufacturerModel: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/AddInstrumentManufacturerModel`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        
        updateInstrumentManufacturerModel: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/UpdateInstrumentManufacturerModel`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteInstrumentManufacturerModel: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/DeleteInstrumentManufacturerModel?plModelKey=${data}`,
                method: 'DELETE',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getInstrumentManufacturerModelList: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/GetInstrumentManufacturerModelsList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateInstrumentModelStatus: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/UpdateInstrumentManufacturerModelStatusChange`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),


        /** Instrument Max Charges */
        getMaxChargesListByReportingGroup: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/GetScopeTypeMaxChargesByReportingGroupList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addScopeTypeMaxCharges: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/AddScopeTypeMaxChargesByReportingGroup`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateScopeTypeMaxCharges: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/UpdateScopeTypeMaxChargesByReportingGroup`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteScopeTypeMaxCharges: builder.mutation({
            query: (data) => ({
                url: `/RepairItemInstruments/DeletescopeTypeMaxChargesByReportingGroup?plScopeTypeKey=${data.plScopeTypeKey}&plReportingGroupKey=${data.plReportingGroupKey}&pdtEffectiveDate=${data.pdtEffectiveDate}&pdtEndDate=${data.pdtEndDate}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
})


export const {
    useGetRepairItemInstrumentsListMutation,
    useLazyGetInstrumentDetailsByScopeTypeKeyQuery,
    useLazyGetAllInstrumentsCategoriesQuery,
    useUpdateInstrumentsMutation,

    /**Manufacturer */
    useLazyGetAllManufacturerQuery,
    useGetInstrumentManufacturerListMutation,
    useAddInstrumentManufacturerMutation,
    useUpdateInstrumentManufacturerStatusMutation,
    useDeleteInstrumentManufacturerMutation,

    /** Instrument Model */
    useAddInstrumentManufacturerModelMutation,
    useUpdateInstrumentManufacturerModelMutation,
    useDeleteInstrumentManufacturerModelMutation,
    useGetInstrumentManufacturerModelListMutation,
    useUpdateInstrumentModelStatusMutation,

    /** Instrument Max Charges */
    useGetMaxChargesListByReportingGroupMutation,
    useAddScopeTypeMaxChargesMutation,
    useUpdateScopeTypeMaxChargesMutation,
    useDeleteScopeTypeMaxChargesMutation,

} = instrumentsAPI;

export default instrumentsAPI;
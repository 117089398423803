/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../app/slice/authSlice";
import { getData } from "../../../utils/LocalStorage/LocalStorageManager";
import { getCookie } from "../../../utils/Cookies/CookieHandler";

import "./Sidebar.scss";

const Sidebar = (props) => {

  const cookie = getCookie("AuthUser");
  const userID = cookie?.user?.lUserKey;

  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [navigationMenuList, setNavigationMenuList] = useState(null);

  const handleClick = (menu, childMenuKey) => {
    if (selectedMenu === menu) {
      setSelectedMenu(null); // Remove class if same menu clicked again
    } else {
      setSelectedMenu(menu); // Add class to the clicked menu
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  }

  useEffect(() => {
    if (cookie.isAuthenticated) {
      const navigationMenu = getData("NavigationMenu");
      const menu = navigationMenu?.filter((item) => item.lMenuKey === 1);
      setNavigationMenuList(menu)
    }
  }, [userID, cookie?.isAuthenticated])

  return (
    <div className="sidebar-section">
      <nav className="sidebar">
        <div className="main-menus">
          <Link to={"/"} className="sidebar-brand">
            <h2>The Total Scope</h2>
          </Link>
          <div className="sidebar-menu">
            <ul className="sidebar-menu-list">
              {navigationMenuList && navigationMenuList.map((menuItem, index) => (
                <li key={index}
                  className={selectedMenu === menuItem.lMenuItemKey ? "menu-item active-menu" : "menu-item"}
                  onClick={() => handleClick(menuItem.lMenuItemKey, menuItem.childMenuKey)}
                >
                  <Link to={menuItem.sPage} className={`${menuItem.children && "menu-arrow"}`}>
                    <i className={menuItem.sIconClass}></i>
                    <span>{menuItem.sMenuItemCaption}</span>
                  </Link>

                  {/* {menuItem.children && (
                      <ul className="sidebar-dropdown">
                        {menuItem.children.map((subMenu, index) => (
                          <li className="dropdown-menus" key={index}>
                            <Link to={subMenu.navigationPath}>{subMenu.sMenuCaption}</Link>
                          </li>
                        ))}
                      </ul>
                    )} */}
                </li>
              ))}
            </ul>
            <div className="bottom-menu ">
              <ul className="sidebar-menu-list">
                <li className="menu-item">
                  <Link>
                    <i className="bi bi-lock"></i>
                    <span>Change Password</span>
                  </Link>
                </li>
                <li className="menu-item" onClick={handleLogout}>
                  <Link>
                    <i className="bi bi-power"></i>
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;

import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getAuthProps } from "../../lib/authenticationLibrary";
// import { logout } from './../../app/slice/authSlice'
const { Mutex } = require('async-mutex');

const mutex = new Mutex();
const IsProdMode = true;
const API_URL = (IsProdMode) ? process.env.REACT_APP_TestSiteBaseUrl : process.env.REACT_APP_BaseUrl

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
        return headers;
    }
});


export const defaultBaseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
        let authData = getAuthProps();
        if (authData) {
            const token = authData.token.token;
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
});

export const customFetchBase = async (args, api, extraOptions) => {
    const authData = getAuthProps();
    let token = null;
    if (authData)
        token = authData.token.token;

    if (!token) {
        window.location.href = '/login';
        return;
    }

    await mutex.waitForUnlock();

    args.headers = {
        ...args.headers,
        Authorization: `Bearer ${token}`,
    };

    let result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        if (result.error.status === 401) {
            window.location.href = '/login';
        }
    }
    return result;
}
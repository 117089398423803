// Import necessary modules and functions
import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
// import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

// Create API using createApi function
const productIdAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'productIdAPI',
    endpoints: (builder) => ({

        getProductIDsListByRepairItemKey: builder.query({
            query: (data) => ({
                url: `/RepairItems/GetProductIDsListByRepairItemKey?plRepairItemKey=${data.plRepairItemKey}&pbIncludeInactive=${data.pbIncludeInactive}&pbIncludeAllReportingGroups=${data.pbIncludeAllReportingGroups}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        })

    }),
});

export const { useLazyGetProductIDsListByRepairItemKeyQuery } = productIdAPI;

export default productIdAPI;